import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import aboutImg from '../Assets/Images/aboutImg.png';

const BuyNow = () => {
  return (
    <>
      <Modal.Header closeButton className='p-0 border-0'></Modal.Header>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row className='swapRow align-items-cente justify-content-center gy-4 g-0'>
            <Col sm={7} md={6} lg={5} xl={5} className='swapCol'>
              <div className='swapDiv h-100'>
                <img src={aboutImg} alt='MANEKI 2.0' className='w-100 h-100' />
              </div>
            </Col>
            <Col md={6} lg={6} xl={5} className='swapCol'>
              <div className='swapTxt text-center'>
                <div className='swapImg'>
                  <img src={aboutImg} alt='MANEKI 2.0' />
                </div>
                <p>SWAP YOUR $SOL TO $MANEKI 2.0</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  )
}

export default BuyNow