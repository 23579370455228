import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import aboutImg from '../Assets/Images/aboutImg.png';

const About = () => {
  return (
    <>
      <Modal.Header closeButton className='p-0 border-0'></Modal.Header>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row className='align-items-center justify-content-center gy-4 gy-md-0'>
            <Col sm={10} md={5}>
              <div className='aboutImg'>
                <img src={aboutImg} alt='MANEKI 2.0' />
              </div>
            </Col>
            <Col md={7}>
              <div className='aboutTxt text-center text-md-start'>
                <h2 className='aboutTitle'>MANEKI 2.0</h2>
                <p className='aboutP'>Solana native cat coin $MANEKI, known as the Japanese beckoning cat, is a legendary leader with the wisdom of ages and the power to bring fortune to those who rally under its banner. $MANEKI is a symbol of good luck, success, prosperity, wisdom and leadership.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  )
}

export default About