import React, { useRef, useState } from 'react'
import MainContext from './MainContext'

const ContextState = (props) => {

    const [isPlaying, setIsPlaying] = useState(false);
    const [showBtn, setShowBtn] = useState(true);

    const videoRef = useRef(null);

    const value = {
        isPlaying, setIsPlaying,
        showBtn, setShowBtn,
        videoRef,
    }

    return (
        <MainContext.Provider value={value}>
            {props.children}
        </MainContext.Provider>
    )
}

export default ContextState