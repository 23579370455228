import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import manekiBg from '../Assets/Images/manekiBg.png';
import manekiVideo from '../Assets/Images/manekiVideo.mp4'
import twitter from '../Assets/Images/Icons/twitter.png';
import telegram from '../Assets/Images/Icons/telegram.png';
import birdeye from '../Assets/Images/Icons/birdeye.png';
import dextools from '../Assets/Images/Icons/dextools.png';
import dexscreen from '../Assets/Images/Icons/dexscreen.png';
import solscan from '../Assets/Images/Icons/solscan.png';
import coingecko from '../Assets/Images/Icons/coingecko.png';
import coinmarket from '../Assets/Images/Icons/coinmarket.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import MainContext from '../Context/MainContext';

const Home = () => {
  const contractAddress = '25hAyBQfoDhfW19ayearrbgvWGwDdNqcHsXS3jQ3mTDJ';
  const context = useContext(MainContext);
  // const [showBtn, setShowBtn] = useState(true);
  // const videoRef = useRef(null);

  const handleToggleClick = () => {
    if (context.videoRef.current) {
      if (context.isPlaying) {
        context.videoRef.current.pause();
      } else {
        context.setShowBtn(false);
        context.videoRef.current.play();
      }
      context.setIsPlaying(!context.isPlaying);
    }
  };

  const handleVideoEnd = () => {
    context.setIsPlaying(false);
    context.videoRef.current.currentTime = 0;
    context.videoRef.current.load();
    context.setShowBtn(true);
  };

  return (
    <>
      <div className="bannerSec position-relative overflow-hidden">
        <div className='manekiBgDiv'>
          <video poster={manekiBg} ref={context.videoRef} className='manekiVdo' onClick={handleToggleClick} onEnded={handleVideoEnd}>
            <source src={manekiVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {!context.showBtn && context.isPlaying &&
          <Button className='toggleBtn' onClick={handleToggleClick}>
            <i className="fa-solid fa-pause" />
          </Button>
        }
        {!context.showBtn && !context.isPlaying &&
          <Button className='toggleBtn' onClick={handleToggleClick}>
            <i className="fa-solid fa-play" />
          </Button>
        }

        {context.showBtn &&
          <Button className='playBtn' onClick={handleToggleClick}>
            <span><i className="fa-solid fa-play" /></span>
            <p className='mb-0'>PLAY 2.0</p>
          </Button>
        }

        <div className={`socialsDiv gap-2 gap-md-3 p-3 ${!context.showBtn ? (context.isPlaying ? 'play' : 'pause') : ''}`}>
          <div className='contractAddress'>
            <CopyToClipboard text={contractAddress}>
              <Button className='p-0 bg-transparent border-0 text-dark'><i className='fa-regular fa-copy' /></Button>
            </CopyToClipboard>
            <span className='ms-2'>{contractAddress}</span>
          </div>

          <ul className='socialIcons gap-2 gap-md-3'>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '50ms' }}>
                <img src={twitter} alt='twitter' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '100ms' }}>
                <img src={telegram} alt='telegram' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '150ms' }}>
                <img src={birdeye} alt='birdeye' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '200ms' }}>
                <img src={dextools} alt='dextools' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '250ms' }}>
                <img src={dexscreen} alt='dexscreen' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '300ms' }}>
                <img src={solscan} alt='solscan' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '350ms' }} className='noLink'>
                <img src={coingecko} alt='coingecko' />
              </a>
            </li>
            <li>
              <a href='#link' target='_blank' rel='noreferrer' style={{ transitionDelay: '400ms' }} className='noLink'>
                <img src={coinmarket} alt='coinmarket' />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Home