import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Pages/Home";
import ROUTES from "./Configs/Routes";
import ContextState from "./Context/ContextState";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ContextState>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME} element={<Home />} />
            </Route>
          </Routes>
        </ContextState>
      </BrowserRouter>
    </div>
  );
}

export default App;
