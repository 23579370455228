import React from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import tokenImg from '../Assets/Images/tokenImg.png';

const Tokenomics = () => {
  return (
    <>
      <Modal.Header closeButton className='p-0 border-0'></Modal.Header>
      <Modal.Body className='p-0'>
        <Container fluid>
          <Row className='align-items-center justify-content-evenly gy-4'>
            <Col sm={7} md={5}>
              <div className='tokenImg'>
                <img src={tokenImg} alt='MANEKI 2.0' />
              </div>
            </Col>
            <Col sm={8} md={5}>
              <div className='tokenTxt text-center'>
                <h2>TOTAL SUPPLY</h2>
                <p>8,888,888,888</p>
                <p><span>0%</span> PRESALE</p>
                <p><span>0%</span> TEAM TOKENS</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </>
  )
}

export default Tokenomics