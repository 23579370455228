import React, { useContext, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../Assets/Images/logo.png';
import ROUTES from '../Configs/Routes';
import MainContext from '../Context/MainContext';
import CustomModal from '../Configs/Atoms/CustomModal/CustomModal';
import About from './About';
import BuyNow from './BuyNow';
import Tokenomics from './Tokenomics';

const Header = () => {
  const context = useContext(MainContext);
  const [manekiModal, setManekiModal] = useState(false);
  const [key, setKey] = useState('');

  const handlePlayClick = () => {
    if (context.videoRef.current) {
      context.setShowBtn(false);
      context.videoRef.current.play();
      context.setIsPlaying(true);
    }
  };

  const handlePauseClick = () => {
    if (context.videoRef.current) {
      context.videoRef.current.pause();
      context.setIsPlaying(false);
    }
  };

  const handleModal = (type) => {
    if (key !== type) {
      setKey(type);
      setManekiModal(true);
      handlePauseClick();
    } else {
      setKey('');
      setManekiModal(false);
      handlePlayClick();
    }
  }

  const handleModalClose = () => {
    setKey('');
    setManekiModal(false);
    handlePlayClick();
  }

  return (
    <>
      <Navbar className={`mainNavbar py-1 py-lg-0 ${context.isPlaying ? 'playing' : ''}`}>
        <Container className=''>
          <Navbar.Brand>
            <Link to={ROUTES.HOME} >
              <img src={logo} alt='logo' className='headerLogo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='ms-auto mx-sm-auto	align-items-center gap-2 gap-sm-3 gap-lg-4'>
              <Nav.Link onClick={() => handleModal('about')} className={key === 'about' ? 'active' : ''}>ABOUT MANEKI</Nav.Link>
              <Nav.Link onClick={() => handleModal('buy')} className={key === 'buy' ? 'active' : ''}>BUY NOW</Nav.Link>
              <Nav.Link onClick={() => handleModal('token')} className={key === 'token' ? 'active' : ''}>TOKENOMICS</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <CustomModal display={manekiModal} handleClose={handleModalClose} size='md' className='manekiModal' backdrop={false}>
        {key === 'about' ?
          <About />
          : key === 'buy' ?
            <BuyNow /> :
            key === 'token' ?
              <Tokenomics />
              : ''
        }
      </CustomModal>
    </>
  )
}

export default Header